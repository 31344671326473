<template>
  <v-col
    v-if="filtersActive"
    class="shrink px-0 mx-0"
  >
    <v-btn
      icon
      x-small
      plain
      tile
      class="mt-1"
      title="Clear Filters"
      @click="$emit('clear')"
    >
      <v-icon>
        fa-solid fa-filter-slash
      </v-icon>
    </v-btn>
  </v-col>
</template>
<script>
  export default {
    props: {
      filters: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      filtersActive () {
        return Object.values(this.filters).filter(x => x.length).length > 0
      },
    },
  }
</script>
